<template>
  <div class="box-graphics">
    <div class="d-flex justify-end">
      <!-- <configuration
        class=""
        @reloadPlanificationCharts="reloadCharts"
      ></configuration> -->
      <seguimiento class="ml-5" @reloadAgilityCharts="reloadCharts"></seguimiento>
    </div>
    <h3 class="text-center tway-header-1">Agilidad Organizacional</h3>
    <!--
    <div class="resumen">
      <div class="resumen-detail">
        <div
          class="resumen-detail-item"
          v-for="(itemResumen, i) in summaryKPINegocio.resumen"
          :key="i"
        >
          <div>{{ itemResumen.description }}</div>
          <div>{{ itemResumen.value }}</div>
          <div>
            <span v-if="itemResumen.signo === '+'" class="material-icons">
              <v-img
                width="25"
                :src="require('@/assets/img/pmo/up-arrow-green.svg')"
              />
            </span>
            <span v-else>
              <v-img
                width="25"
                :src="require('@/assets/img/pmo/down-arrow-red.svg')"
            /></span>
          </div>
        </div>
      </div>
      <div class="resumen-bar">
        <StackedBars100
          :series="summaryKPINegocio.series"
          :options="summaryKPINegocio.options"
          height="150"
          title="Resumen"
        />
      </div>
    </div>
    -->
    <div class="list-top-10">
      <div>
        <div class="mx-4 mt-1 pt-4 tway-violet--text">
          <p>
            Eficiencia
            <ToolTips
              width="300"
              description="Proporción de tiempo promedio en que un requerimiento es trabajado, sobre el tiempo total, por Sprint."
            />
          </p>
        </div>
        <div>
          <VueApexCharts
            ref="flowEfficiency"
            :series="flowEfficiency.series"
            :options="flowEfficiency.options"
            height="300"
            type="area"
          />
        </div>
      </div>
      <div>
        <div class="mx-4 mt-1 pt-4 tway-violet--text">
          <p>
            Velocidad
            <ToolTips width="300" description="Requerimientos promedios del Backlog completados por Sprint." />
          </p>
        </div>
        <div>
          <VueApexCharts
            ref="flowRate"
            :series="flowRate.series"
            :options="flowRate.options"
            height="300"
            type="area"
          />
        </div>
      </div>
    </div>
    <div class="list-top-10">
      <div>
        <div class="mx-4 mt-1 pt-4 tway-violet--text">
          <p>
            Predictibilidad
            <ToolTips
              width="300"
              description="Valor de negocio promedio entregado al cliente, sobre valor total planificado, por Sprint."
            />
          </p>
        </div>
        <div>
          <VueApexCharts
            ref="flowPredictability"
            type="area"
            height="300"
            :series="flowPredictability.series"
            :options="flowPredictability.options"
          />
        </div>
      </div>
      <div>
        <div class="mx-4 mt-1 pt-4 tway-violet--text">
          <p>
            Tiempo de Ciclo
            <ToolTips
              width="300"
              description="Tiempo promedio transcurrido entre que un Requerimiento ingresa al workflow y es entregado al cliente."
            />
          </p>
        </div>
        <div>
          <VueApexCharts
            ref="flowTime"
            :series="flowTime.series"
            :options="flowTime.options"
            height="300"
            type="area"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Seguimiento from "../../components/pmo/agility/seguimiento.vue";
import OrganizationalAgilityService from "@/services/pmo/agility/organizationalAgilityService";
import VueApexCharts from "vue-apexcharts";
import ToolTips from "../../components/pmo/utils/ToolTips";

export default {
  name: "KPIBusiness",
  components: {
    Seguimiento,
    VueApexCharts,
    ToolTips,
  },
  data() {
    return {
      charts: {
        timeLine: {
          serie: null,
        },
        stacked: {
          serie: [],
        },
      },
      flowEfficiency: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 550,
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"], // takes an array which will be repeated on columns
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#16C62E"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
              colorStops: [],
            },
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#16C62E",
              },
              labels: { style: { colors: "#16C62E" } },
              title: {
                text: "% Eficiencia",
                style: { color: "#16C62E" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      flowRate: {
        series: [],
        options: {
          series: [
            {
              name: "Velocidad",
              data: [27, 28, 26, 32, 31, 43, 46, 46],
            },
          ],
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "area",
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"], // takes an array which will be repeated on columns
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7319D5", "#B2B2B2", "#AEEA00", "#4A148C", "#7F7F7F", "#16C62E"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
              colorStops: [],
            },
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "Velocidad",
                style: { color: "#7319D5" },
              },
              tooltip: { enabled: true },
              min: 0,
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      flowPredictability: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "area",
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"], // takes an array which will be repeated on columns
            },
          },
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
              colorStops: [],
            },
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7319D5", "#B2B2B2", "#AEEA00", "#4A148C", "#7F7F7F", "#16C62E"],
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "% Predictibilidad",
                style: { color: "#7319D5" },
              },
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      flowTime: {
        series: [],
        options: {
          series: [
            {
              name: "Tiempo de Ciclo",
              data: [44, 47, 63, 66, 52, 55, 53, 51, 49, 52, 48, 51],
            },
          ],
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 550,
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"], // takes an array which will be repeated on columns
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#16C62E"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
              colorStops: [],
            },
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#16C62E",
              },
              labels: { style: { colors: "#16C62E" } },
              title: {
                text: "Tiempo de Ciclo",
                style: { color: "#16C62E" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
    };
  },

  methods: {
    reloadCharts() {
      this.callService();
    },
    async callService() {
      await OrganizationalAgilityService.getFlowEfficiency(this.project)
        .then((data) => {
          this.$refs.flowEfficiency.updateOptions({
            xaxis: {
              categories: data.series[0].categories,
            },
          });
          this.flowEfficiency.series = data.series;
        })
        .catch((err) => console.dir(err));

      await OrganizationalAgilityService.getFlowRate(this.project)
        .then((data) => {
          this.$refs.flowRate.updateOptions({
            xaxis: {
              categories: data.series[0].categories,
            },
          });
          this.flowRate.series = data.series;
        })
        .catch((err) => console.dir(err));

      await OrganizationalAgilityService.getFlowPredictability(this.project)
        .then((data) => {
          this.$refs.flowPredictability.updateOptions({
            xaxis: {
              categories: data.series[0].categories,
            },
          });
          this.flowPredictability.series = data.series;
        })
        .catch((err) => console.dir(err));

      await OrganizationalAgilityService.getFlowTime(this.project)
        .then((data) => {
          this.$refs.flowTime.updateOptions({
            xaxis: {
              categories: data.series[0].categories,
            },
          });
          this.flowTime.series = data.series;
        })
        .catch((err) => console.dir(err));
    },
  },
  async mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;
    this.callService();
  },
};
</script>

<style lang="scss" scoped>
.box-graphics {
  max-width: 1400px;
  min-width: 1100px;
}

.graphics {
  display: flex;
  flex-direction: column;
}

.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}

.resumen {
  display: flex;
  width: 100%;
  height: 250px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
}

.resumen > .resumen-detail {
  width: 17%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.resumen > .resumen-detail > .resumen-detail-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.resumen > .resumen-detail > .resumen-detail-item > div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55%;
}
.resumen > .resumen-detail > .resumen-detail-item > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 15%;
}

.resumen > .resumen-bar {
  display: flex;
  width: 60%;
  height: auto;
}
.resumen-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
}
.resumen-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.seguimiento {
  width: 100%;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
  height: 500px;
}
.seguimiento-costo {
  width: 45%;
  margin: 2px;
}
.seguimiento-periodo {
  width: 45%;
  margin: 2px;
}
.list-top-10 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px 20px 20px;
  margin-top: 50px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  margin: 25px 0px 25px 0px;
  div {
    width: 45%;
    div {
      width: 100%;
    }
    //border: 1px solid black;
  }
}

.risk_classification {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin: 0px 5px 0px 5px;
}
.risk {
  width: 15%;
  margin: 0px 5px 0px 5px;
}
.concurrence {
  width: 15%;
  margin: 0px 5px 0px 5px;
}
.impact {
  width: 15%;
  margin: 0px 5px 0px 5px;
}
.strategy {
  width: 10%;
  margin: 0px 5px 0px 5px;
}
.contingency {
  width: 20%;
  margin: 0px 5px 0px 5px;
}
.responsible {
  width: 20%;
  margin: 0px 5px 0px 5px;
}
</style>

<template>
  <div class="text-right">
    <v-dialog v-model="dialog" persistent height="auto" width="1150">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="tway-violet--text accent-4"> mdi-chart-line </v-icon>
          Seguimiento
        </v-btn>
      </template>

      <v-card :class="'modalSystem'">
        <v-card>
          <div class="container">
            <v-btn icon right @click="dialog = false" class="float-right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <h3 class="text-center">Seguimiento de Agilidad</h3>
            <h4 class="text-center font-weight-regular">Ingresa nuevo Seguimiento</h4>
            <v-simple-table class="table mt-5" fixed-header>
              <template>
                <thead>
                  <tr>
                    <th class="text-center" width="15%" scope="col">Mes / Año</th>
                    <th class="text-center" width="17%" scope="col">Eficiencia</th>
                    <th class="text-center" width="17%" scope="col">Velocidad</th>
                    <th class="text-center" width="17%" scope="col">Predictibilidad</th>
                    <th class="text-center" width="17%" scope="col">Tiempo de Ciclo</th>
                    <th class="text-center" width="17%" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr :v-if="items.length > 0" v-for="item in items" :key="item">
                    <td class="text-center">
                      <v-menu
                        ref="menu"
                        v-model="item.menu"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.date"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="item.date" type="month" no-title scrollable> </v-date-picker>
                      </v-menu>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.eficiencia"
                        maxlength="15"
                        minlenght="0"
                        type="number"
                        min="0"
                        max="100"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="item.velocidad" maxlength="15" minlenght="0" outlined></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.predictibilidad"
                        maxlength="15"
                        minlenght="0"
                        type="number"
                        min="0"
                        max="100"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="item.tiempoCiclo" maxlength="15" minlenght="0" outlined></v-text-field>
                    </td>
                    <td class="text-center buttons">
                      <v-btn
                        class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                        outlined
                        color="indigo lighten-2"
                        @click="saveAgility()"
                      >
                        <div v-if="!loading.buttonSaveTrack">
                          <v-icon class="tway-violet--text"> mdi-plus </v-icon>
                          Agregar
                        </div>
                        <div v-else>Loading....</div>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider class="my-6"></v-divider>
            <v-alert
              v-model="alert.saveAgility"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="ml-2 mr-2"
            >
              {{ messageError.saveAgility }}
            </v-alert>
            <!-- <v-simple-table v-if="trackTable.length > 0" class="table mt-5"> -->
            <h4 class="text-center font-weight-regular">Listado de Seguimiento Agilidad</h4>
            <v-alert
              v-model="alert.updateAgility"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="ml-2 mr-2"
            >
              {{ messageError.updateAgility }}
            </v-alert>
            <v-simple-table class="table mt-5">
              <template>
                <thead>
                  <tr>
                    <th class="text-left" scope="col">ID</th>
                    <th class="text-left" scope="col">Mes / Año</th>
                    <th class="text-left" scope="col">Eficiencia</th>
                    <th class="text-left" scope="col">Velocidad</th>
                    <th class="text-left" scope="col">Predictibilidad</th>
                    <th class="text-left" scope="col">Tiempo de Ciclo</th>
                    <th class="text-left" width="150" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :v-if="agilityItems.length > 0"
                    v-for="entry in agilityItems"
                    :key="entry.index"
                    :class="entry.isVisible ? hidden : activeClass"
                    class="edit_row"
                  >
                    <td class="text-center">{{ entry.index }}</td>
                    <td class="text-center">
                      <v-menu
                        ref="menu"
                        v-model="entry.menu"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="entry.date"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="entry.date" type="month" no-title scrollable> </v-date-picker>
                      </v-menu>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="entry.efficiency" outlined></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="entry.rate"
                        type="number"
                        maxlength="5"
                        minlenght="0"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field item-text="name" v-model="entry.predictability" outlined></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="entry.cycleTime"
                        type="number"
                        maxlength="5"
                        minlenght="0"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center buttons">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="edit" @click="saveItem(entry)" v-bind="attrs" v-on="on">
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="primary"
                            class="save"
                            @click="updateAgility(entry)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>save</v-icon>
                          </v-btn>
                        </template>
                        <span>Guardar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteAgility(entry.identificador)"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-row>
              <v-col md="12" class="justify-center">
                <div class="btn d-flex justify-center mt-6">
                  <v-btn
                    class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text justify-center"
                    outlined
                    color="indigo lighten-2"
                    @click="closeConfigurationDialog()"
                  >
                    <v-icon class="tway-violet--text">mdi-content-save</v-icon>
                    Guardar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import organizationalAgilityService from "@/services/pmo/agility/organizationalAgilityService";

export default {
  data() {
    return {
      items: [
        {
          eficiencia: 0,
          velocidad: 0,
          predictibilidad: 0,
          tiempoCiclo: 0,
          date: new Date().toISOString().substr(0, 7),
          menu: false,
        },
      ],
      agilityItems: [],
      maxIndex: 0,
      dialog: false,
      nameRules: [(v) => !!v || "Requerido.", (v) => v.length <= 15 || "Máximo 15 carácteres."],
      isVisible: true,
      activeClass: "is-visible",
      loading: {
        buttonSaveTrack: false,
      },
      alert: {
        saveAgility: false,
        updateAgility: false,
      },
      messageError: {
        saveAgility: "",
        updateAgility: "",
      },
    };
  },
  methods: {
    closeConfigurationDialog() {
      this.dialog = false;
      this.$emit("reloadAgilityCharts");
    },
    clearForm() {},
    saveItem(item) {
      item.isVisible = !item.isVisible;
      this.$forceUpdate();
    },
    saveAgility() {
      const request = {
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
        date: this.items[0].date,
        efficiency: this.items[0].eficiencia,
        predictability: this.items[0].predictibilidad,
        rate: this.items[0].velocidad,
        cycleTime: this.items[0].tiempoCiclo,
      };
      this.loading.buttonSaveTrack = true;

      organizationalAgilityService
        .saveAgility(request)
        .then(() => {
          this.getAgility();
          this.clearForm();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.saveAgility = true;
          this.messageError.saveAgility = message;
        })
        .finally(() => {
          this.loading.buttonSaveTrack = false;
          setTimeout(() => (this.alert.saveAgility = false), 4000);
        });
    },
    updateAgility(entry) {
      const request = {
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
        idAgility: entry.identificador,
        date: entry.date,
        efficiency: entry.efficiency,
        predictability: entry.predictability,
        rate: entry.rate,
        cycleTime: entry.cycleTime,
      };
      this.loading.buttonSaveTrack = true;

      organizationalAgilityService
        .saveAgility(request)
        .then(() => {
          this.getAgility();
          this.clearForm();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.updateAgility = true;
          this.messageError.updateAgility = message;
        })
        .finally(() => {
          this.loading.buttonSaveTrack = false;
          setTimeout(() => (this.alert.updateAgility = false), 4000);
        });
    },
    deleteAgility(idAgility) {
      organizationalAgilityService
        .deleteAgility(idAgility)
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          organizationalAgilityService
            .getAgilities(this.project)
            .then((data) => {
              this.agilityItems = data.items;
            })
            .catch((err) => console.dir(err));

          setTimeout(() => (this.alertBox.on = false), 4000);
        });
    },
    getAgility() {
      organizationalAgilityService
        .getAgilities(this.project)
        .then((data) => {
          this.agilityItems = data.items;
          this.maxIndex = data.general.maxIndex;
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          this.loading.saveUnit = false;
          setTimeout(() => (this.alert.saveUnit = false), 4000);
        });
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.getAgility();
  },
};
</script>

<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light,
.edit_row .v-text-field__details {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.v-btn.primary {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}
.edit_row .v-input__slot,
.edit_row .v-select {
  min-height: 35px !important;
  max-height: 35px !important;
  margin-bottom: 0px !important;
}
.edit_row .v-text-field--outlined .v-label {
  top: 8px;
  font-size: 13px !important;
}
.edit_row .v-input__append-inner {
  margin-top: 5px !important;
}
.edit_row .v-text-field__details {
  display: none;
}
.edit_row .v-select__selection,
.edit_row .v-text-field__slot input {
  font-size: 0.875rem;
}
.edit_row label.v-label.v-label--active.theme--light {
  top: 18px;
}
tr.edit_row td {
  height: 60px !important;
}
.modalSystem .edit_row fieldset {
  border-color: #fff !important;
  background-color: #f2f2f2;
}
.modalSystem .v-data-table__wrapper {
  max-height: 408px;
  overflow: auto;
}
.modalSystem .edit_row.is-visible fieldset {
  border-color: transparent !important;
  background-color: transparent;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
.modalSystem .edit_row .v-input__prepend-outer {
  display: none;
}
.peso {
  float: left;
  margin-right: 5px;
  top: 7px;
  position: relative;
}
tr.edit_row td .edit {
  display: none;
}
tr.edit_row.is-visible td {
  pointer-events: none;
}
tr.edit_row.is-visible td.buttons {
  pointer-events: visible;
}
tr.edit_row.is-visible td .save {
  display: none !important;
}
tr.edit_row.is-visible td .edit {
  display: inline-grid !important;
}
tr.edit_row.is-visible .v-input__append-inner {
  display: none !important;
}
tr.edit_row.is-visible label.v-label.v-label--active.theme--light {
  display: none;
}
</style>
